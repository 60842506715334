import React, { useState } from "react";
import { Badge, Table, Tooltip } from "reactstrap";

import { Badges, MUIIcons, H5, H4 } from "../../AbstractElements";
import { PendingStatus, RejectedStatus, YtsStatus } from "../../Api/constants";
import BadgeWithIcon from "../../CommonElements/BadgeWithIcon";
import CardHeaderDropDown from "../../CommonElements/CommonCardHeader/CardHeaderDropDown";
import Divider from "../../CommonElements/Divider";
import {
  Approve,
  Approved,
  Decline,
  Declined,
  NotYetStarted,
  PendingForReview,
  Rejected,
  UploadFile,
} from "../../utils/Constant";
import "./style.scss";
import {
  capitalizeFirstLetter,
  handleAllFilesDownload,
} from "../../utils/helper/helper";
import { hasPermissionToComponent } from "../../utils/helper/permission";

interface PendingSkillData {
  lo: string[];
  type: string;
  learnerDocs?: string;
  trainerDocs?: string;
  status?: string;
  actions?: string;
}

interface PendingSkillTableProps {
  data: PendingSkillData[];
  TableHeaders: string[];
  onDropdownClick?: (id: string) => void;
  onUpdateStatus?: (id: string, type: string) => void;
}

const ILPPendingSkillTable: React.FC<PendingSkillTableProps> = ({
  data,
  TableHeaders,
  onDropdownClick,
  onUpdateStatus,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState<Record<number, boolean>>({});

  const toggleTooltip = (index: number) => {
    setTooltipOpen(prev => ({ ...prev, [index]: !prev[index] }));
  };

  const renderStatusBadge = (status: string | undefined) => {
    switch (status) {
      case Approved:
        return (
          <BadgeWithIcon
            badgeClassName="badge-approved-bg"
            iconName="DoneOutlined"
            text={capitalizeFirstLetter(Approved)}
          />
        );
      case PendingStatus:
        return (
          <H4 className="d-flex flex-row gap-5 align-items-center">
            <Badges pill>{PendingForReview}</Badges>
          </H4>
        );
      case Rejected:
        return (
          <BadgeWithIcon
            iconName="CloseOutlined"
            text={capitalizeFirstLetter(Declined)}
          />
        );
      default:
        return (
          <H4>
            <Badges pill>{NotYetStarted}</Badges>
          </H4>
        );
    }
  };

  return (
    <div className="dataTables_wrapper">
      <div className="table-responsive theme-scrollbar">
        <Table
          className="table display dataTable no-footer custom-skill-table"
          id="selling-product"
        >
          <thead className="light-background custom-skill-table-thead">
            <tr>
              {TableHeaders.map((header, index) => (
                <th className=".b-b-secondary text-black fw-bold" key={index}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="fs-10 custom-skill-table-tbody">
            {data?.map((item: any, i) => {
              return (
                <React.Fragment key={i}>
                  <tr className="text-black">
                    <td className="lo-cell">
                      <div>
                        <span id={`tooltip-lo-${i}`}>
                          <H5 className="mt-3 d-flex gap-2">
                            {item?.lo[0]}
                            <Badges className="pointer">
                              {item?.lo?.length > 1 &&
                                `${item?.lo?.length - 1}+`}
                            </Badges>
                          </H5>
                        </span>
                        {item?.lo?.length > 1 && (
                          <Tooltip
                            placement="top"
                            isOpen={tooltipOpen[i] || false}
                            target={`tooltip-lo-${i}`}
                            toggle={() => toggleTooltip(i)}
                          >
                            {item?.lo.slice(1).join(", ")}
                          </Tooltip>
                        )}
                      </div>
                    </td>
                    <td>{item?.type}</td>
                    <td>
                      {item?.learnerDocs && item?.learnerDocs?.length > 0 ? (
                        <Badges
                          onClick={() =>
                            handleAllFilesDownload(item?.learnerDocs)
                          }
                          color="light text-dark"
                        >
                          <MUIIcons
                            className="pointer"
                            iconName={"InsertDriveFileOutlined"}
                            size={24}
                          />
                          <div className="notification-wrapper">
                            <MUIIcons
                              className="pointer"
                              iconName={"DownloadForOfflineOutlined"}
                              size={24}
                            />
                            {item?.learnerDocs?.length > 0 && (
                              <Badge className="notification-badge">
                                {item?.learnerDocs?.length}
                              </Badge>
                            )}
                          </div>
                        </Badges>
                      ) : null}
                    </td>

                    <td>
                      {item?.trainerDocs && item?.trainerDocs?.length > 0 ? (
                        <Badges
                          onClick={() =>
                            handleAllFilesDownload(item?.trainerDocs)
                          }
                          color="light text-dark"
                        >
                          <MUIIcons
                            className="pointer"
                            iconName={"InsertDriveFileOutlined"}
                            size={24}
                          />
                          <div className="notification-wrapper">
                            <MUIIcons
                              className="pointer"
                              iconName={"DownloadForOfflineOutlined"}
                              size={24}
                            />
                            {item?.trainerDocs?.length > 0 && (
                              <Badge className="notification-badge">
                                {item?.trainerDocs?.length}
                              </Badge>
                            )}
                          </div>
                        </Badges>
                      ) : null}
                    </td>

                    <td>{renderStatusBadge(item?.status)}</td>

                    <td>
                      {hasPermissionToComponent("UPLOAD_SKILL_DOC") &&
                      (item?.status === YtsStatus ||
                        item?.status === RejectedStatus) ? (
                        <CardHeaderDropDown
                          mainTitle
                          firstItem={UploadFile}
                          onClickItem={() => onDropdownClick(item?.id)}
                        />
                      ) : hasPermissionToComponent("APPROVE_SKILL_DOC") &&
                        item?.status === PendingStatus ? (
                        <div className="d-flex flex-row gap-2">
                          <div>
                            <span id={"tooltip-approve"}>
                              <MUIIcons
                                onClick={() =>
                                  onUpdateStatus(item?.id, "declined")
                                }
                                className="primary-icon-color pointer"
                                iconName="CancelOutlined"
                                size={27}
                              />
                            </span>

                            <Tooltip
                              placement="top"
                              isOpen={tooltipOpen[101010] || false}
                              target={"tooltip-approve"}
                              toggle={() => toggleTooltip(101010)}
                            >
                              {Decline}
                            </Tooltip>
                          </div>

                          <div>
                            <span id={"tooltip-reject"}>
                              <MUIIcons
                                onClick={() =>
                                  onUpdateStatus(item?.id, "approved")
                                }
                                className="text-success pointer"
                                iconName="CheckCircleOutlined"
                                size={27}
                              />
                            </span>

                            <Tooltip
                              placement="top"
                              isOpen={tooltipOpen[1010101] || false}
                              target={"tooltip-reject"}
                              toggle={() => toggleTooltip(1010101)}
                            >
                              {Approve}
                            </Tooltip>
                          </div>
                        </div>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                  {i < data.length - 1 && (
                    <tr>
                      <td className="p-0" colSpan={TableHeaders.length}>
                        <Divider />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default ILPPendingSkillTable;
