import { useEffect, useState } from "react";

import { ALL_USERS_STATUS } from "../../Api/constants";
import UserList, { UserCardDetails } from "../../container/UserList";
import { useAppDispatch, useAppSelector } from "../../ReduxToolkit/Hooks";
import { getUsersList } from "../../ReduxToolkit/Reducers/UserSlice";
import { dynamicImage } from "../../Service";

const UserManagement = () => {
  const dispatch = useAppDispatch();
  const { usersList } = useAppSelector(state => state.user);
  const [activeUsers, setActiveUsers] = useState<UserCardDetails[]>([]);
  const [, setSuspendedUsers] = useState<UserCardDetails[]>([]);

  useEffect(() => {
    dispatch(getUsersList({ role: ALL_USERS_STATUS }));
  }, [dispatch]);

  useEffect(() => {
    const userMapObj = {};
    usersList?.users?.forEach(user => {
      userMapObj[user.id] = user;
    });
    const mapUserToCardDetails = (user: any): UserCardDetails => ({
      id: user.id,
      name: user.user_name,
      detail: user.designation,
      cwsAndCatRecId: 0,
      userProfileUrl: dynamicImage("dashboard-4/user.png"),
      expertiseLevel: "From Darwin Box",
      location: "From Darwin Box..",
      rmName: user.mapped_rm?.length > 0 ? user.mapped_rm[0].name : "-",
      phoneNumber: 0,
      mailId: user.email,
      jobRoleId: user.job_role_id,
    });

    const active = Object.values(userMapObj)
      .filter(
        (user: any) => user.status === "active" || user.status === undefined
      )
      .map(mapUserToCardDetails);

    const suspended = Object.values(userMapObj)
      .filter(
        (user: any) => user.status === "suspended" || user.status === undefined
      )
      .map(mapUserToCardDetails);

    setActiveUsers(active);
    setSuspendedUsers(suspended);
  }, [usersList]);

  return (
    <div className="page-body page-body-margin">
      <UserList activeUsers={activeUsers} suspendedUsers={[]} />
    </div>
  );
};

export default UserManagement;
