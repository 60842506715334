import { getFromLocalStorage, LOGGED_IN_USER } from "./localStorageutils";

const roles = {
  reporting_manager: {
    FILTER_TABS: true,
    REQUEST_TABS: true,
    ALL_FILTER_TABS: false,
    RAISE_REQUEST: true,
    COURSE_TABS: false,
    EDIT_REQUEST: true,
    ADD_COURSE: false,
    REJECT_RM_DETAILS: false,
    TRAINER_BOND_DETAILS: false,
    VIEW_RM_REQUEST: true,
    APPROVE_STAGES: false,
    EDIT_COURSE_DETAILS: false,
    COURSE_MANAGEMENT: {
      ROOT: true,
      COURSE_REQUEST: true,
      COURSE_LIST: true,
    },
    CREATE_COURSE_STAGES: false,
    ADD_COMMENT: false,
    EDIT_ASSESSMENTS: false,
    REQUEST_LIST: true,
    REVIEW_SME_LO: false,
    PUBLISH_CONTENT: false,
    CREATE_ASSESSMENT: false,
    UN_PUBLISH_COURSE: false,
    CREATE_CURRICULUM: true,
    CREATE_QUESTION_BANK: false,
    COURSE_LIST_ONLY_PUBLISHED_TAB: true,
    VIEW_TIME_LINE_COURSE: true,
    WBT_PUBLISH: false,
    CREATE_ASSESSMENT_CATLOG: false,
    MASTER_DATA: false,
    USER_MANAGEMENT: {
      ROOT: true,
      SUSPENDED_USERS: false,
      ADD_CURRICULUM: false,
      MAP_COURSE: false,
      MAP_SGA: false,
      MAP_ASSESSMENT: false,
      ASSIGN_YEAR_TO_COURSE: true,
      ASSIGN_ILP: false,
      MODIFY_YEAR: true,
      APPROVE_MODIFIED_YEAR: false,
    },

    MY_ASSESSMENT: false,
    ASSESSMENT_MANAGEMENT: {
      ROOT: true,
      ASSESSMENT_CATALOG: true,
      ASSESSMENT_REVIEW: true,
    },
    MODIFY_PUBLISHED_ASSESSMENT: false,
    REVOKE_EDITOR_ACCESS: false,
    REVIEW_SELF_ASSESSMENT: true,
    UPLOAD_SKILL_DOC: false,
    APPROVE_SKILL_DOC: true,
  },
  training_manager: {
    FILTER_TABS: true,
    REQUEST_TABS: false,
    ALL_FILTER_TABS: true,
    RAISE_REQUEST: false,
    COURSE_TABS: true,
    EDIT_REQUEST: false,
    ADD_COURSE: true,
    REJECT_RM_DETAILS: true,
    TRAINER_BOND_DETAILS: true,
    VIEW_RM_REQUEST: true,
    APPROVE_STAGES: true,
    EDIT_COURSE_DETAILS: true,
    COURSE_MANAGEMENT: {
      ROOT: true,
      COURSE_REQUEST: true,
      COURSE_LIST: true,
    },
    CREATE_COURSE_STAGES: false,
    ADD_COMMENT: true,
    EDIT_ASSESSMENTS: false,
    REQUEST_LIST: true,
    REVIEW_SME_LO: true,
    PUBLISH_CONTENT: true,
    CREATE_ASSESSMENT: false,
    CREATE_QUESTION_BANK: true,
    UN_PUBLISH_COURSE: false,
    CREATE_CURRICULUM: true,
    VIEW_TIME_LINE_COURSE: true,
    WBT_PUBLISH: true,
    CREATE_ASSESSMENT_CATLOG: true,
    MASTER_DATA: true,
    USER_MANAGEMENT: {
      ROOT: true,
      SUSPENDED_USERS: true,
      ADD_CURRICULUM: true,
      MAP_COURSE: true,
      MAP_SGA: true,
      ASSIGN_YEAR_TO_COURSE: false,
      ASSIGN_ILP: true,
      MODIFY_YEAR: false,
      APPROVE_MODIFIED_YEAR: true,
      MAP_ASSESSMENT: true,
    },

    MY_ASSESSMENT: false,
    ASSESSMENT_MANAGEMENT: {
      ROOT: true,
      ASSESSMENT_CATALOG: true,
      ASSESSMENT_REVIEW: true,
    },
    MODIFY_PUBLISHED_ASSESSMENT: true,
    REVOKE_EDITOR_ACCESS: true,
    REVIEW_SELF_ASSESSMENT: false,
    UPLOAD_SKILL_DOC: false,
    APPROVE_SKILL_DOC: false,
  },
  trainer: {
    FILTER_TABS: true,
    REQUEST_TABS: false,
    ALL_FILTER_TABS: true,
    RAISE_REQUEST: false,
    COURSE_TABS: true,
    EDIT_REQUEST: false,
    ADD_COURSE: false,
    REJECT_RM_DETAILS: false,
    TRAINER_BOND_DETAILS: false,
    VIEW_RM_REQUEST: false,
    APPROVE_STAGES: false,
    EDIT_COURSE_DETAILS: false,
    COURSE_MANAGEMENT: {
      ROOT: true,
      COURSE_REQUEST: false,
      COURSE_LIST: true,
    },
    CREATE_COURSE_STAGES: true,
    ADD_COMMENT: true,
    EDIT_ASSESSMENTS: true,
    REQUEST_LIST: false,
    REVIEW_SME_LO: false,
    PUBLISH_CONTENT: false,
    CREATE_ASSESSMENT: true,
    UN_PUBLISH_COURSE: false,
    CREATE_CURRICULUM: false,
    CREATE_QUESTION_BANK: false,
    COURSE_LIST_ONLY_PUBLISHED_TAB: false,
    VIEW_TIME_LINE_COURSE: true,
    WBT_PUBLISH: false,
    CREATE_ASSESSMENT_CATLOG: true,
    MASTER_DATA: false,
    USER_MANAGEMENT: {
      ROOT: false,
      SUSPENDED_USERS: false,
      ADD_CURRICULUM: false,
      MAP_COURSE: false,
      MAP_SGA: false,
      ASSIGN_YEAR_TO_COURSE: false,
      ASSIGN_ILP: false,
      MODIFY_YEAR: false,
      APPROVE_MODIFIED_YEAR: false,
      MAP_ASSESSMENT: false,
    },
    MY_ASSESSMENT: false,
    ASSESSMENT_MANAGEMENT: {
      ROOT: true,
      ASSESSMENT_CATALOG: true,
      ASSESSMENT_REVIEW: true,
    },
    MODIFY_PUBLISHED_ASSESSMENT: false,
    REVOKE_EDITOR_ACCESS: false,
    REVIEW_SELF_ASSESSMENT: false,
    UPLOAD_SKILL_DOC: false,
    APPROVE_SKILL_DOC: false,
  },
  sme: {
    FILTER_TABS: false,
    REQUEST_TABS: false,
    ALL_FILTER_TABS: false,
    RAISE_REQUEST: false,
    COURSE_TABS: true,
    EDIT_REQUEST: false,
    ADD_COURSE: false,
    REJECT_RM_DETAILS: false,
    TRAINER_BOND_DETAILS: false,
    VIEW_RM_REQUEST: false,
    APPROVE_STAGES: true,
    EDIT_COURSE_DETAILS: false,
    COURSE_MANAGEMENT: {
      ROOT: true,
      COURSE_REQUEST: false,
      COURSE_LIST: true,
    },
    CREATE_COURSE_STAGES: false,
    ADD_COMMENT: true,
    EDIT_ASSESSMENTS: false,
    REQUEST_LIST: false,
    REVIEW_SME_LO: false,
    PUBLISH_CONTENT: false,
    CREATE_ASSESSMENT: false,
    UN_PUBLISH_COURSE: false,
    CREATE_CURRICULUM: false,
    CREATE_QUESTION_BANK: false,
    COURSE_LIST_ONLY_PUBLISHED_TAB: false,
    VIEW_TIME_LINE_COURSE: true,
    WBT_PUBLISH: false,
    CREATE_ASSESSMENT_CATLOG: false,
    MODIFY_PUBLISHED_ASSESSMENT: false,
    REVOKE_EDITOR_ACCESS: false,
    USER_MANAGEMENT: {
      ROOT: false,
      SUSPENDED_USERS: false,
      ADD_CURRICULUM: false,
      MAP_COURSE: false,
      MAP_SGA: false,
      ASSIGN_YEAR_TO_COURSE: false,
      ASSIGN_ILP: false,
      MODIFY_YEAR: false,
      APPROVE_MODIFIED_YEAR: false,
      MAP_ASSESSMENT: false,
    },
    REVIEW_SELF_ASSESSMENT: false,
    UPLOAD_SKILL_DOC: false,
    APPROVE_SKILL_DOC: false,
  },
  learner: {
    FILTER_TABS: false,
    REQUEST_TABS: false,
    ALL_FILTER_TABS: false,
    RAISE_REQUEST: false,
    COURSE_TABS: false,
    EDIT_REQUEST: false,
    ADD_COURSE: false,
    REJECT_RM_DETAILS: false,
    TRAINER_BOND_DETAILS: false,
    VIEW_RM_REQUEST: false,
    APPROVE_STAGES: false,
    EDIT_COURSE_DETAILS: false,
    COURSE_MANAGEMENT_SUB_MENU: false,
    COURSE_MANAGEMENT: {
      ROOT: false,
      COURSE_REQUEST: false,
      COURSE_LIST: false,
    },
    CREATE_COURSE_STAGES: false,
    ADD_COMMENT: false,
    EDIT_ASSESSMENTS: false,
    REQUEST_LIST: false,
    REVIEW_SME_LO: false,
    PUBLISH_CONTENT: false,
    CREATE_ASSESSMENT: false,
    UN_PUBLISH_COURSE: false,
    CREATE_CURRICULUM: false,
    CREATE_QUESTION_BANK: false,
    COURSE_LIST_ONLY_PUBLISHED_TAB: false,
    VIEW_TIME_LINE_COURSE: false,
    WBT_PUBLISH: false,
    CREATE_ASSESSMENT_CATLOG: false,
    MASTER_DATA: false,
    USER_MANAGEMENT: {
      ROOT: false,
      SUSPENDED_USERS: false,
      ADD_CURRICULUM: false,
      MAP_COURSE: false,
      MAP_SGA: false,
      ASSIGN_YEAR_TO_COURSE: false,
      ASSIGN_ILP: false,
      MODIFY_YEAR: false,
      APPROVE_MODIFIED_YEAR: false,
      MAP_ASSESSMENT: false,
    },
    MY_ASSESSMENT: true,
    ASSESSMENT_MANAGEMENT: {
      ROOT: false,
      ASSESSMENT_CATALOG: false,
      ASSESSMENT_REVIEW: false,
    },
    MODIFY_PUBLISHED_ASSESSMENT: false,
    REVOKE_EDITOR_ACCESS: false,
    REVIEW_SELF_ASSESSMENT: false,
    UPLOAD_SKILL_DOC: true,
    APPROVE_SKILL_DOC: false,
  },
};

export const Session = () => {
  return getFromLocalStorage(LOGGED_IN_USER);
};

export const hasPermissionToComponent = (componentPath: string) => {
  const session = Session();

  const keys = componentPath.split(".");

  if (session && session.role) {
    let permission = roles[session.role];

    for (const key of keys) {
      if (permission && permission[key] !== undefined) {
        permission = permission[key];
      } else {
        return false;
      }
    }
    return !!permission;
  }

  return false;
};

export const getPermissionObject = (component: string) => {
  const session = Session();
  if (session && session.role) {
    const permission = roles[session.role];
    if (permission && permission[component] !== undefined) {
      return permission[component];
    }
  }
};

export const isCurrentUserIsCourseOwner = id => {
  const session = Session();

  if (session && session.role && session.id) {
    const hasPermission = hasPermissionToComponent(
      "MODIFY_PUBLISHED_ASSESSMENT"
    );
    if (hasPermission || session.id === id) {
      return true;
    }
  }

  return false;
};

export const currentUserId = () => {
  const session = Session();

  if (session && session.id) {
    return session.id;
  }

  return null;
};
